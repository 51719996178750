export default [
  {
    key: 'productCategoryId',
    label: 'field.category',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'productCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'productBrandId',
    label: 'field.brand',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'productBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    type: 'text',
    operator: '%',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
  },
  {
    key: 'isCalculateVolume',
    label: 'field.calculateVolume',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
  },
  {
    key: 'isFeature',
    label: 'field.feature',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
  },
  {
    key: 'oppVisible',
    label: 'field.oppVisible',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
  },
  {
    key: 'smiaVisible',
    label: 'field.smiaVisible',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'single-selection',
    translatable: true,
    clearable: true,
    cast: 'boolean',
    options: [
      { text: 'field.yes', value: '1' },
      { text: 'field.no', value: '0' },
    ],
  },
]
