export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'unitPrice',
    label: 'field.unitPrice',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'saleUom',
    label: 'field.salesUom',
    hideToggle: true,
  },
  {
    key: 'salesCode',
    label: 'field.salesCode',
    hideToggle: true,
  },
  {
    key: 'salesType',
    label: 'field.salesType',
    hideToggle: true,
  },
  {
    key: 'minQty',
    label: 'field.minQty',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'startingDate',
    label: 'field.startingDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'endingDate',
    label: 'field.endingDate',
    type: 'date',
    hideToggle: true,
  },
];
