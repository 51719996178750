<template>
  <div>
    <b-button
      :disabled="!$can('sync', resource)"
      :variant="!$can('sync', resource) ? 'secondary' : 'success'"
      @click="show"
      v-if="repository"
    >
      {{ $t("button.sync") }}
    </b-button>
    <b-modal
      ref="modal"
      centered
      no-close-on-backdrop
      hide-footer
      v-model="isShow"
    >
      <div class="mb-1">
        <validation-observer ref="syncForm" #default="{ invalid }">
          <n-form-confirmation
            key="syncForm"
            :form="$refs.syncForm"
            :disabled="invalid"
          >
            <n-input :fields="fields" v-model="data"></n-input>

            <b-row>
              <b-col cols="12" class="text-right">
                <n-button-loading
                  :loading="loading"
                  variant="success"
                  @submit="submit"
                >
                  {{ $t("button.sync") }}
                </n-button-loading>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BSpinner, BRow, BCol, BButton } from "bootstrap-vue";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import moment from "moment";
import NButtonLoading from "@/components/NButtonLoading";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BSpinner,
    BRow,
    BCol,
    BButton,

    NFormConfirmation,
    NInput,
    NButtonLoading,
  },
  props: {
    repository: {},
    resource: {
      type: String,
      default: "",
    },
    module: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      data: {
        fromDate: moment().format("YYYY-MM-DD"),
      },
      syncReference: null,
    };
  },
  mounted() {
    this.$bus.$on("firestore-sycn", (object) => {
      if (
        object?.syncReference &&
        object?.syncReference == this.syncReference
      ) {
        if (object?.success) {
          this.$hideSyncing();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            autoHideDelay: 2000,
            props: {
              title: this.$t("alert.syncSuccess"),
              iconType: "line-awesome",
              icon: "las la-cloud-download-alt",
              variant: "light-success",
              textVariant: "success",
            },
          });
        } else {
          this.$hideSyncing();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            autoHideDelay: 2000,
            props: {
              title: this.$t("alert.syncFail"),
              iconType: "line-awesome",
              icon: "las la-cloud-download-alt",
              variant: "light-danger",
              textVariant: "danger",
            },
          });
        }
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off("firestore-sycn");
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    submit() {
      this.$refs.syncForm.validate().then((success) => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(
              this.$t("alert.syncData", {
                module: this.module,
                date: moment(this.data.fromDate)
                  .locale(this.$i18n.locale)
                  .format("LL"),
              }),
              {
                title: this.$t("alert.areYouSure"),
                size: "sm",
                buttonSize: "sm",
                okVariant: "success",
                okTitle: this.$t("button.sync"),
                cancelTitle: this.$t("button.cancel"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                this.save();
              }
            });
        }
      });
    },
    save() {
      this.syncReference = new Date().getTime();
      this.$showSyncing(this.module);
      this.loading = true;
      this.repository
        .sync({
          backDaySync: moment().diff(moment(this.data.fromDate), "days"),
          syncReference: this.syncReference,
        })
        .then(() => {
          this.loading = false;
          this.hide();
        })
        .catch(() => {
          this.loading = false;
          this.$hideSyncing();
        });
    },
  },
  setup() {
    const fields = FormInput;

    return {
      fields,
    };
  },
};
</script>
