export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'productCategory',
    label: 'field.category',
    hideToggle: true,
  },
  {
    key: 'productBrand',
    label: 'field.brand',
    hideToggle: true,
  },
  {
    key: 'image',
    label: 'field.image',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'productCode',
    label: 'field.productCode',
    hideToggle: true,
  },
  {
    key: 'name',
    label: 'field.name',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'unitPrice',
    label: 'field.unitPrice',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'saleUom',
    label: 'field.salesUom',
    hideToggle: true,
  },
  {
    key: 'rating',
    label: 'field.rating',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'isCalculateVolume',
    label: 'field.calculateVolume',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'isFeature',
    label: 'field.feature',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'oppVisible',
    label: 'field.oppVisible',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'smiaVisible',
    label: 'field.smiaVisible',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'dpVisible',
    label: 'field.dpVisible',
    thClass: 'text-center',
    tdClass: 'text-center',
    type: 'toggle',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.createdDate',
    hideToggle: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
];
